@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

/* Box sizing rules */

*,
*::before,
*::after {
    /* box-sizing: border-box; */
}


/* Set core body defaults */

body {
    /* min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    padding-bottom: 250px;
        */
}


/* Inherit fonts for inputs and buttons */

input,
button,
textarea,
select {
    /* font: inherit; */
}

:root {
    /* --black: #1c1e21;
    --white: #ffffff;
    --tw-color1: #a855f7;
    --tw-color1-hover: #9333ea;
        */
}

body {
    /* background: var(--black);
    font-family: "Inter", sans-serif;
    color: var(--white);
    /* display: flex; */
    /* margin: 0;
                                            text-align: center; */
}

h2 {
    /* font-size: 2rem; */
}

footer {
    /* width: 100%;
    height: 120px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    background-color: #192d39;
        */
}

footer p {
    /* background-color: #0d1a21; */
}

.title {
    font-size: 40px;
    margin-bottom: 0px;
    font-weight: bold;
}

.subTitle {
    font-size: 20px;
    font-weight: bold;
}

.detail {
    color: white;
    font-size: 16px;
}

p {
    color: white;
}

/* ถ้าหน้าจอต่ำกว่า 500 */
@media (max-width:500px) {
    .fullWidth {
        display: none;
    }

    .minWidth {
        display: flex;
    }

    .fullImageEvent {
        max-width: "auto";
    }

}

/* ถ้าหน้าจอมากกว่า 500 */
@media (min-width:500px) {
    .minWidth {
        display: none;
    }

    .minWidthCarousel {
        display: none;
    }

    .minImageEvent {
        max-width: "315px";
    }
}

li {
    color: white;
}