@media (max-width:500px) {
    .fullMenu {
        display: none;
    }

    .minMenu {
        display: flex;
    }
}

@media (min-width:500px) {
    .minMenu {
        display: none;
    }
}