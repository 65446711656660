.connect {
    position: fixed;
    top: 0px;
    left: 0px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

.navbar {
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    background-color: #192d39;
}

.navbar img {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0.5rem;
    /* border: 1px solid red; */
}

.tagBar {
    width: 70%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    /* border: 1px solid red; */
}

.tagBar ul {
    width: 70%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    /* border: 1px solid red; */
}

.tagBar ul li {
    width: 60%;
    padding: 1.5rem;
}

.tagBar ul li a {
    color: #fff;
    text-decoration: none;
    /* border: 1px solid red; */
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    background-color: #171f25;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

.main img {
    width: 5%;
    height: 5%;
}

.nftBoxGrid {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #14232d;
}

.optionSelectBox {
    width: 27%;
    height: 15%;
    border-radius: 40px;
    justify-content: center;
    background-color: rgb(54, 82, 103);
    box-shadow: 3px 3px 7px rgba(172, 220, 255, 0.2);
    border: 1px solid rgba(45, 213, 255, 0.3);
    margin: 1rem;
    padding: 0rem;
}

.title {
    /* height: 80px;
    overflow: hidden;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    position: relative;
    background-color: #1a3f4f; */
}

.selectBoxTitle {
    display: block;
    width: 200px;
    height: 45px;
    transform: translateX(33%);
    border-radius: 40px;
    text-size-adjust: 40ch;
    border-bottom: 4px solid #2e4aa7;
    box-shadow: 3px 3px 7px rgba(119, 196, 255, 0.2);
    background-color: rgb(57, 111, 230);
}

.h2 {
    margin: 0.3rem;
}

.h4 {
    margin: -0.5rem;
}

.selectBoxTitle a {
    text-size-adjust: 40ch;
    text-decoration: none;
    color: #fff;
}

.selectBoxDescription {
    height: 80px;
    overflow: hidden;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    position: relative;
    padding: 1rem;
    margin: -0.02rem;
    background-color: #1a3f4f;
}

.community {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -0.5rem;
    /* border: 1px solid red; */
}

.community ul {
    display: flex;
    justify-items: center;
    align-items: center;
    transform: translateX(-3%);
    list-style: none;
    justify-content: center;
}

.community img {
    width: 6%;
    padding: 0rem;
}

.community a {
    display: block;
    align-items: center;
    justify-items: center;
    text-decoration: none;
    color: #fff;
    text-align: center;
}

.mainButton {
    cursor: pointer;
    display: inline-flex;
    appearance: none;
    align-items: center;
    -webkit-box-align: center;
    justify-content: center;
    white-space: nowrap;
    vertical-align: middle;
    outline: 2px solid transparent;
    outline-offset: 2px;
    line-height: 1.2;
    font-weight: 600;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 150ms;
    height: 3rem;
    min-width: 3rem;
    font-size: 1rem;
    background: #e5e5ea;
    background-image: linear-gradient(to left, #cc25b3 0%, #418dff 101.52%);
    color: #fff;
    width: 180px;
    text-align: center;
    border-radius: 9999px;
    border-style: none;
}

.spacerBottom {
    margin-bottom: 16px;
}